var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "阈值批量设置",
            top: "5%",
            visible: _vm.showDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "180px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "统计时间间隔（小时）", prop: "position" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 10 },
                    model: {
                      value: _vm.addform.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "num", $$v)
                      },
                      expression: "addform.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上月耗水平均值", prop: "position" } },
                [
                  _c("span", [_vm._v("15t")]),
                  _c("span", { staticClass: "valTip" }, [
                    _vm._v("上个月的平均耗水，可供参考"),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "水能预警值设置", prop: "position" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 10 },
                    model: {
                      value: _vm.addform.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "num", $$v)
                      },
                      expression: "addform.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "水能预警值系数", prop: "position" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 10 },
                    model: {
                      value: _vm.addform.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "num", $$v)
                      },
                      expression: "addform.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上月耗电平均值", prop: "position" } },
                [
                  _c("span", [_vm._v("1500度")]),
                  _c("span", { staticClass: "valTip" }, [
                    _vm._v("上个月的平均耗电，可供参考"),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电能预警值设置", prop: "position" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 10 },
                    model: {
                      value: _vm.addform.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "num", $$v)
                      },
                      expression: "addform.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "水能预警值系数", prop: "position" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 10 },
                    model: {
                      value: _vm.addform.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "num", $$v)
                      },
                      expression: "addform.num",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }