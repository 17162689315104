<template>
 <!-- 客房事件页面 -->
 <!-- 头部选择器 -->
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="80px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="3">
      <el-form-item label="品牌">
       <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="门店">
       <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="能耗类型">
       <!-- <el-select v-model="searchForm.expendType" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in expendTypes" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select> -->
       <el-radio-group v-model="searchForm.expendType">
        <el-radio-button v-for="(item,index) in expendTypes" :key="index" :label="item.value" :value="item.value">{{item.label}}</el-radio-button>
        <!-- <el-radio-button label="北京"></el-radio-button>
      <el-radio-button label="广州"></el-radio-button>
      <el-radio-button label="深圳"></el-radio-button> -->
       </el-radio-group>

      </el-form-item>
     </el-col>
     <el-col :span="6">
      <el-form-item label="统计时间" prop="createTime">
       <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
     </el-col>

    </el-row>
    

   </el-form>
   <el-row>
    <el-col :span="12">
     <!-- <el-input v-model="snapPath">
      <i slot="suffix" class="el-input__icon el-icon-folder" style="cursor: pointer;" @click="handleGetPath('snapPath')"></i>
     </el-input> -->
     <el-button size="mini" type="primary" @click="openSet">批量设置</el-button>
    </el-col>
    <el-col :span="12">
     <div class="grid-content reset-button">
      <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
      <el-button size="mini" @click="reset">重置</el-button>
      <!-- <el-button size="mini" v-if="showexport" @click="exportExcel(0)" :loading="downLoading">导出</el-button> -->
     </div>
    </el-col>
   </el-row>
  </el-card>
  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.solt_TEXT">
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
      </div>
      <div v-else-if="item.value=='opsAverageTime'">
       {{showLongTimes(scope.row.opsAverageTime)}}
      </div>

      <div v-else-if="item.value=='opsPercent'">
       {{scope.row[item.value]?scope.row[item.value]*100:0}}%
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>
  <expendDiaoLog ref="expendDiaoLog" />
 </div>
</template>
<script>
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 labelShowDate,
} from "@/api/common.js";
import {
 makeRoomList,
 getBrandSelect,
 getHotelSelect,
 makeRoomOpsRate,
} from "@/api";
import { getDay, getLongTime } from "@/api/common.js";
import expendDiaoLog from "./expendSet.vue";
const typeLists = {};
export default {
 components: {
  expendDiaoLog,
 },
 data() {
  return {
   snapPath: null,
   height: null,
   tablist: [],
   expendTypes: [
    {
     label: "电耗管理",
     value: 0,
    },
    {
     label: "水耗管理",
     value: 1,
    },
   ],
   execlTab: [
    {
     label: "品牌",
     value: "brandName",
    },
    {
     label: "门店",
     value: "hotelName",
     width: 280,
    },
    {
     label: "卡类型",
     value: "position",
     solt_TEXT: "cardTypes",
    },
    {
     label: "姓名",
     value: "employeeName",
    },
    {
     label: "卡号",
     value: "cardSn",
    },
    // {
    //  label: "开始时间",
    //  value: "startTime",
    // },
    // {
    //  label: "结束时间",
    //  value: "endTime",
    // },
    {
     label: "做房时间",
     value: "startTime",
    },
    {
     label: "做房次数",
     value: "opsCount",
    },
    {
     label: "做房占比",
     value: "opsPercent",
    },
    {
     label: "做房平均时长",
     value: "opsAverageTime",
    },
   ],
   roomForm: {},
   loading: false,
   downLoading: false,
   // 查询的数据
   searchForm: {
    brandId: null,
    hotelId: null,
    expendType: 0,
    createTime: null,
   },
   total: 0,
   pageNum: 1,
   pageSize: 10,
   listQuery: { expendType: 0 },
   brandSelectData: null,
   hotelSelectData: null,
  };
 },

 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 295;
 },
 computed: {
  typeLists() {
   let obj = typeLists;
   obj.cardTypes = this.$store.state.cardTypes;
   return obj;
  },
  showexport: function () {
   return this.$store.state.roleMenu.includes("makeRoom:export");
  },
 },

 created() {
  this.getList();
  this.initialize();
 },
 methods: {
  handleGetPath(path) {
   /*
    * 该方法只支持IE.
    * 语法：strDir=Shell.BrowseForFolder(Hwnd,Title,Options,[RootFolder])
    * 参数：Hwnd：包含对话框的窗体句柄（handle），一般设置为0
    * Title：将在对话框中显示的说明，为字符串
    * Options：使用对话框的特殊方式，为长整数，一般设置为0
    * RootFolder：（可选的），用来设置浏览的最顶层文件夹，缺省时为“桌面”，可以将其设置为一个路径或“特殊文件夹常数”
    * */
   try {
    let filePath;
    let objSrc = new ActiveXObject("Shell.Application").BrowseForFolder(
     0,
     "请选择保存路径",
     0,
     ""
    );
    console.log(objSrc,'----objSrc');
    if (objSrc != null) {
     filePath = objSrc.Items().Item().Path;
     if (filePath.charAt(0) == ":") {
      alert("请选择文件夹.");
      return false;
     }
     console.log(filePath,'-filePath');
     this.snapPath = filePath;
    }
   } catch (e) {
    alert(
     e +
      "请设置IE，Internet选项－安全－自定义级别－将ActiveX控件和插件前3个选项设置为启用，然后再尝试。"
    );
    return false;
   }
  },
  initTime() {
   let startTime = getDay(-30),
    endTime = getDay();
   this.query.startTime = startTime;
   this.query.endTime = endTime;
   this.searchForm.createTime = [startTime, endTime];
  },
  openSet() {
   console.log("open");
   this.$refs.expendDiaoLog.openSet();
  },

  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },

  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery = {};
   console.log(this.searchForm);
   for (let key in this.searchForm) {
    if (key == "createTime") {
     if (this.searchForm.createTime) {
      this.listQuery.startTime = this.searchForm.createTime[0];
      this.listQuery.endTime = this.searchForm.createTime[1];
     } else {
      this.listQuery.startTime = null;
      this.listQuery.endTime = null;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.hotelSelectData = null;
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   //  this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   this.searchForm = {
    brandId: null,
    hotelId: null,
    expendType: 0,
    createTime: null,
   };
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   makeRoomList(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped >
.leftInput {
 width: 80%;
}
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }

}
</style>


