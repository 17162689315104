<template>
 <div class="my-box">

  <el-dialog title="阈值批量设置" top="5%" :visible.sync="showDialog" width="40%">
   <el-form :model="addform" ref="addform" :rules="myrules" label-width="180px">

    <el-form-item label="统计时间间隔（小时）" prop="position">
     <el-input-number v-model="addform.num" controls-position="right"  :min="1" :max="10"></el-input-number>
    </el-form-item>
    <el-form-item label="上月耗水平均值" prop="position">
     <span>15t</span>
     <span class="valTip">上个月的平均耗水，可供参考</span>
    </el-form-item>
    <el-form-item label="水能预警值设置" prop="position">
     <el-input-number v-model="addform.num" controls-position="right"  :min="1" :max="10"></el-input-number>
    </el-form-item>
    <el-form-item label="水能预警值系数" prop="position">
     <el-input-number v-model="addform.num" controls-position="right"  :min="1" :max="10"></el-input-number>
    </el-form-item>
    <el-form-item label="上月耗电平均值" prop="position">
     <span>1500度</span>
     <span class="valTip">上个月的平均耗电，可供参考</span>
    </el-form-item>
    <el-form-item label="电能预警值设置" prop="position">
     <el-input-number v-model="addform.num" controls-position="right"  :min="1" :max="10"></el-input-number>
    </el-form-item>
    <el-form-item label="水能预警值系数" prop="position">
     <el-input-number v-model="addform.num" controls-position="right"  :min="1" :max="10"></el-input-number>
    </el-form-item>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="showDialog=false">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { trackDetail, getHotelSelect, workerAdd } from "@/api";
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";
// import * as files from '../'
// import keys from '../../'
export default {
 props: ["brandSelectData"],
 data() {
  return {
   and: "-and-",
   loading: false,
   showDialog: false,
   addform: {
    hotel: null,
    brand: null,
    position: null,
    cardType: null,
    cardSn: null,
    employeeName: null,
    phoneNumber: null,
   },
   floorSelectData: [],
   hotelSelectData: [],
   myrules: {
    hotel: [{ required: true, message: "请选择", trigger: "change" }],
    brand: [{ required: true, message: "请选择", trigger: "change" }],
    position: [{ required: true, message: "请选择", trigger: "change" }],
    // phoneNumber: [{ required: true, message: "请选择", trigger: "change" }],
    phoneNumber: [
     { required: true, message: "请输入手机号码", trigger: "blur" },
     {
      pattern: /^1[345789]\d{9}$/,
      message: "请填写正确的手机号码",
      trigger: "blur",
     },
    ],
    employeeName: [
     { required: true, message: "请选择", trigger: "blur" },
     { max: 10, message: "最多可输入 10 个字符", trigger: "blur" },
    ],
   },
  };
 },
 computed: {
  cardTypes() {
   return this.$store.state.cardTypes;
  },
 },
 mounted() {
// //    const files = require.context('file:///D:/work2/', false).keys();
	// console.log(files)
 },
 methods: {
  selectOne(val) {
   this.addform.hotel = null;
   let id = val.split(this.and)[0];
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
     console.log(this.hotelSelectData, "; this.hotelSelectData");
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  openSet() {
   this.showDialog = true;
  },
  getData(obj) {
   let data = {};
   let brand = obj.brand.split(this.and);
   data.brandId = brand[0];
   data.brandName = brand[1];
   let hotel = obj.hotel.split(this.and);
   data.hotelId = hotel[0];
   data.hotelName = hotel[1];
   data.position = obj.position;
   data.cardType = obj.cardType;
   data.cardSn = obj.cardSn;
   data.employeeName = obj.employeeName;
   data.phoneNumber = obj.phoneNumber;

   return data;
  },
  confirmAdd(formName) {
   // addform.province = selectedOptions
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     let data = this.getData(this.addform);
     console.log(data);
     workerAdd(data)
      .then((res) => {
       console.log(res);
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success(res.data.message);
        this.showDialog = false;
        this.$parent.getList();
       } else {
        this.$message.error(res.data.message);
       }
      })
      .catch((err) => {
       console.log(err);
       this.loading = false;
      });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
 },
};
</script>
<style lang="scss" scoped>
.valTip {
 padding-left: 20px;
 color: #f56c6c;
}
</style>


